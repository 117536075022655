/**
 * Get the values in pixels or anything else otherwise. If value is number,
 * pixels "Xpx" will be returned. If given value is for e.g. "1rem", the "1rem"
 * will be returned as is.
 *
 * @param value
 * @return {string}
 */
export const getPixelsOrString = function (value) {
	return typeof value === 'number' ? `${value}px` : value;
};

/**
 * Returns if given value is number or string
 * @param {*} value
 * @return {boolean}
 */
export const isNumberOrString = function (value) {
	return typeof value === 'number' || typeof value === 'string';
};

/**
 * Get the style object for passed margins (m, mt, mr, mb, ml)
 *
 * @param props
 * @return {{margin: string}|{margin: *}}
 */
export const getStyleForMargins = function (props) {
	const {m = null, mt = null, mr = null, mb = null, ml = null} = props;

	if (m !== null) {
		return {margin: getPixelsOrString(m)};
	}

	return {
		marginTop: getPixelsOrString(mt),
		marginRight: getPixelsOrString(mr),
		marginBottom: getPixelsOrString(mb),
		marginLeft: getPixelsOrString(ml)
	};
};


const attachGlobalEvent = (evt, fn) => {
	if (window.attachEvent) {
		window.attachEvent(evt, fn);
	} else if (window.addEventListener) {
		window.addEventListener(evt, fn, true);
	}
};

const deAttachGlobalEvent = (evt, fn) => {
	if (window.detachEvent) {
		window.detachEvent(evt, fn);
	} else if (window.removeEventListener) {
		window.removeEventListener(evt, fn, true);
	}
};

/**
 * @param {Function} fn
 */
export const onWindowKeyDown = (fn) => {
	attachGlobalEvent('keydown', fn);
};

/**
 * @param {Function} fn
 */
export const offWindowKeyDown = (fn) => {
	deAttachGlobalEvent('keydown', fn);
};

/**
 * @param {Function} fn
 */
export const onWindowScroll = (fn) => {
	attachGlobalEvent('scroll', fn);
};

/**
 * @param {Function} fn
 */
export const offWindowScroll = (fn) => {
	deAttachGlobalEvent('scroll', fn);
};

/**
 * @param {Function} fn
 */
export const onWindowResize = (fn) => {
	attachGlobalEvent('resize', fn);
};

/**
 * @param {Function} fn
 */
export const offWindowResize = (fn) => {
	deAttachGlobalEvent('resize', fn);
};
