import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {PRIMARY_COLOR} from '../constants';

export default function Button({children, size = null, backgroundColor = null, ...otherProps}) {
	return (
		<StyledButton type="button" $size={size} $backgroundColor={backgroundColor} {...otherProps}>
			{children}
		</StyledButton>
	);
}

Button.propTypes = {
	children: PropTypes.node,
	size: PropTypes.oneOf(['sm', 'md']),
	backgroundColor: PropTypes.string
};

const StyledButton = styled.button`
	display: inline-block;
	vertical-align: middle;
	outline: none;
	text-decoration: none;
	position: relative;
	appearance: none;
	box-sizing: border-box;

	cursor: pointer;
	touch-action: manipulation;
	border: 1px solid transparent;

	text-transform: none;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: break-word;
	overflow: hidden;
	user-select: none;
	line-height: normal;

	background: ${({$backgroundColor}) => $backgroundColor || PRIMARY_COLOR};
	color: #ffffff;
	margin: 0.25rem;
	border-radius: 4px;
	transform: none;
	will-change: transform;
	transition: all 300ms ease;

	${({$size}) => {
		switch ($size) {
			case 'md':
			default:
				return 'padding: 0.5rem 1rem; font-size: 1.2rem; font-weight: bold;';

			case 'sm':
				return 'padding: 0.35rem 0.75rem; font-size: 0.9rem; font-weight: normal;';
		}
	}}

	&:first-of-type {
		margin-left: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			box-shadow: 0 3px 5px rgba(30, 134, 232, 0.55);
			transform: translateY(-4px);
		}
	}
`;
