import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import {PRIMARY_COLOR_3} from '../constants';
import Buttons from '../ui/Buttons';
import Button from '../ui/Button';
import {getPixelsOrString, isNumberOrString} from '../helpers';
import useEnvContext from '../hooks/useEnvContext';

export default function SignUpCTA({mt = null, mb = null, backgroundColor = null, buttonColor = null}) {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "nazdravlje.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const {appUrl} = useEnvContext();

	return (
		<StyledSignUp $mt={mt} $mb={mb} $backgroundColor={backgroundColor}>
			<div>
				<div>
					<h3>Pridruži se već danas</h3>
					<strong>Uslugu QR cjenika možeš početi koristiti - ODMAH!</strong>
					<Buttons textAlign="center">
						<Button as={OutboundLink} href={appUrl('/registracija')} backgroundColor={buttonColor}>
							Isprobaj besplatno
						</Button>
					</Buttons>
				</div>
				<aside>
					<Img fluid={data.file.childImageSharp.fluid} objectFit="cover" objectPosition="center center" className="img" />
				</aside>
			</div>
		</StyledSignUp>
	);
}

SignUpCTA.propTypes = {
	mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	backgroundColor: PropTypes.string,
	buttonColor: PropTypes.string
};

const StyledSignUp = styled.div`
	display: block;
	width: 100%;
	background: ${({$backgroundColor}) => $backgroundColor || PRIMARY_COLOR_3};
	margin: ${({$mt}) => (isNumberOrString($mt) ? getPixelsOrString($mt) : 0)} 0
		${({$mb}) => (isNumberOrString($mb) ? getPixelsOrString($mb) : 0)} 0;

	> div {
		width: 100%;
		max-width: 1300px;
		display: block;

		> div:first-child {
			// content stuff
			padding: 3rem 0;
			box-sizing: border-box;
		}
	}

	h3 {
		display: block;
		text-align: center;
		font-size: 2.25rem;
		font-weight: bold;
		padding: 0 1rem;
		margin: 0;
		box-sizing: border-box;
		color: #ffffff;
	}

	strong {
		display: block;
		margin: 0 auto;
		padding: 1rem;
		box-sizing: border-box;
		width: 100%;
		max-width: 600px;
		text-align: center;
		color: #ffffff;
	}

	.img {
		width: 100%;
	}

	@media (min-width: 767px) {
		> div {
			display: flex;
			align-items: stretch;
			margin: 0 auto;

			h3,
			strong,
			.buttons {
				text-align: left;
			}

			> div:first-child {
				// content stuff
				flex: 1;
				padding: 3rem;
			}

			> aside {
				flex: 1;

				.img {
					height: 100%;
				}
			}
		}
	}
`;
