import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function Buttons({children, textAlign}) {
	return <StyledButtons className="buttons" $textAlign={textAlign}>{children}</StyledButtons>;
}

Buttons.propTypes = {
	children: PropTypes.node,
	textAlign: PropTypes.oneOf(['left', 'center', 'right']).isRequired
};

const StyledButtons = styled.div`
	display: block;
	text-align: ${({$textAlign}) => $textAlign};
	margin: 2rem 0;
	padding: 0 1rem;
	box-sizing: border-box;
`;
